export interface Config {
  backendPath: string,
  localizationPath: string;
  inactivityTimeout: number;
}

export function getConfig(): Readonly<Config> {
  return {
    backendPath: '/wtt-backend',
    localizationPath: '/wtt-localization',
    // increased to 90 minutes [VAD-698]
    inactivityTimeout: 1000 * 60 * 90
  };
}
