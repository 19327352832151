import { TranslationText } from '../../../apis/localization/localization-service';
import _ from 'lodash';

export const MIN_DURATION = 1000;
export const DEFAULT_DURATION = 20000;

export const INACTIVITY_LOGOUT_TOAST: Toast = {
  type: 'default',
  message: { key: 'Logout due to inactivity' },
  duration: Infinity,
};

export const CONNECTION_ERROR_TOAST: Toast = {
  type: 'error',
  message: { key: 'Connection error!' },
  duration: Infinity,
};

export const SESSION_EXPIRED_TOAST: Toast = {
  type: 'error',
  message: { key: 'Login token expired! Please login again!' },
  duration: Infinity,
};


export interface Toast {
  message: TranslationText,
  type: ToastType
  /**
   * default: {@link DEFAULT_DURATION}<br>
   * min: {@link MIN_DURATION}
   */
  duration?: number,
}

export type ToastSearch = Omit<Toast, 'duration'>

export type ToastType = 'default' | 'success' | 'error'

export class ToastService {
  showToast(toast: Toast): void {
    window.dispatchEvent(new CustomEvent('wtt.toast.show', { detail: _.cloneDeep(toast) }));
  }

  hideToast(toast: ToastSearch) {
    window.dispatchEvent(new CustomEvent('wtt.toast.hide', { detail: _.cloneDeep(toast) }));
  }
}

export const toastService = new ToastService();
