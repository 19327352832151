import { createStore } from '@stencil/store';
import { Me } from '../user/user-api';
import { Build } from '@stencil/core';

export type CachedMe = Me & {
  lastUpdateTimestamp: number
}

export enum WttServiceGrant {
  READER = 'wtt-svc-reader',
  AUTHOR_EXT = 'wtt-svc-author-ext',
  AUTHOR_INT = 'wtt-svc-author-int',
  ADMIN = 'wtt-svc-admin',
}

export namespace WttServiceGrant {
  export function parse(value: string) {
    if (Object.values(WttServiceGrant).indexOf(value as WttServiceGrant) >= 0) {
      return value as WttServiceGrant;
    } else {
      return null;
    }
  }
}

interface AuthState {
  me?: DeepReadonly<CachedMe>;
  grants: WttServiceGrant[];
}


const { state } = createStore<AuthState>({
  me: undefined,
  grants: [],
});

export const authState = {
  get me(): DeepReadonly<CachedMe> | undefined {
    return state.me;
  },

  set me(me: CachedMe | undefined) {
    state.me = me;
  },

  forgetMe() {
    state.me = undefined;
    state.grants = [];
  },

  get grants() {
    return state.grants;
  },

  set grants(grants: WttServiceGrant[]) {
    state.grants = grants;

    if (Build.isDev) {
      console.log('Set grants to: ' + state.grants);
    }
  },
};
