import { Api, DefaultApiErrorResponse } from '../api';

export class LocalizationApi extends Api {

  baseUrl(): string {
    return location.protocol + '//' + location.host + this.config.localizationPath;
  }

  async getLocalizations(language: string): Promise<LocalizationResponse> {
    const response = await this.fetch('GET', `/api/v1/localization/${language}`)
      .catch(() => null);

    if (!response) {
      console.warn('Localization API did not respond');
      return { status: 'CONNECTION_ERROR' };
    }

    if (!response.ok) {
      console.warn('Localization API responded with unknown error');
      return { status: 'UNKNOWN_ERROR' };
    }

    const responseBody = await response.json();
    return {
      status: 'SUCCESS',
      ...responseBody
    };
  }

  async getContextHelp(language: string, context: string): Promise<string> {
    const response = await this.fetch(
      'GET',
      `/api/v1/localization/${language}/${context}`,
      {
        headers: {
          'Accept': 'text/html'
        }
      }).catch(() => null);

    if (!response || !response.ok) {
      return '';
    }

    return response.text()
  }

  getContextHelpUrl(language: string, context: string): string {
    return `${this.baseUrl()}/api/v1/localization/${language}/${context}`
  }
}

export const localizationApi = new LocalizationApi();

export type LocalizationResponse = LocalizationSuccessResponse | DefaultApiErrorResponse;
export interface LocalizationSuccessResponse {
  status: 'SUCCESS';
  keys: Record<string, string>;
  format: Format;
}

interface Format {
  locale: string;
  formats: Formats;
}

interface Formats {
  float: NumberFormat;
  long: NumberFormat;
  currency: CurrencyFormat;
  date: string;
  time: string;
  hour: string;
  datetime: string;
  timestamp: string;
}

interface NumberFormat {
  minimumIntegerDigits: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

interface CurrencyFormat {
  currency: string;
  minimumIntegerDigits: number;
  minimumFractionDigits: number;
}
