import { Api } from './api';

export class BackendApi extends Api {
  baseUrl(): string {
    return location.protocol + '//' + location.host + this.config.backendPath;
  }
}

export interface WttRestResponseBody<T> {
  data: T;
  time: number;
  responseCode: number;
  responseMessage: string;
  httpStatus: string;
  user: string;
  uri: string;
}

export interface PaginatedWttRestResponseBody<T> extends WttRestResponseBody<T[]> {
  page: number;
  total: number;
  length: number;
  indexFrom: number;
  next: string;
  previous: string;
  totalPages: number;
  isLast: boolean;
}
