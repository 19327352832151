export class PersistenceService {
  persistValue(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getPersistedValue(key: string): string | null {
    return localStorage.getItem(key);
  }

  removePersistedValue(key: string) {
    localStorage.removeItem(key);
  }

  private getPersistedObject(key: string): object | undefined {
    const object = localStorage.getItem(key);
    return object ? JSON.parse(object) : undefined;
  }

  persistSubObject(parent: string, key: string, object: object) {
    const persistedObject = this.getPersistedObject(parent);
    localStorage.setItem(parent, JSON.stringify({
      ...persistedObject,
      [key]: object,
    }));
  }

  getPersistedSubObject<T extends object>(parent: string, key: string): T | undefined {
    const object = localStorage.getItem(parent);
    return object ? JSON.parse(object)[key] : undefined;
  }
}

export const persistenceService = new PersistenceService();

export function hashKey(key: string): string {
  if (key.length === 0) {
    return '';
  }

  let hashedKey = 0;
  for (let i = 0; i < key.length; i++) {
    const charCode = key.charCodeAt(i);
    hashedKey = (hashedKey << 3) + charCode;
    hashedKey = hashedKey & hashedKey;
  }

  return hashedKey.toString();
}
